

const IdleRPG = () => {


    return (
        <div>
            <div className="idlerpg-header-cont">
                <div className="idlerpg-header-title">
                    
                </div>
            </div>
            <iframe frameborder="0" src="https://itch.io/embed/1645199?linkback=true&amp;dark=true" width="552" height="167"><a href="https://novaze.itch.io/theforevergeneration">The Forever Generation by Novaze</a></iframe>
        </div>
    )

}

export default IdleRPG;